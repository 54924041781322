@import url('./Utils/Color.css');
#logo-content path{
    animation:
      dash 2s ease-in-out infinite,
      spin 2s ease-in-out infinite;
    stroke-width: 130px;
  }
  #logo{
    height:15rem;
    transform: scale(3);
    /* width: 60rem; */
    
  }




  #logo-content > :nth-child(1),
  #logo-content > :nth-child(3),
  #logo-content > :nth-child(4),
  #logo-content > :nth-child(6) {
    /* color for J H N and E */
    stroke: var(--color-5)
  }

  #logo-content > :nth-child(5),
  #logo-content > :nth-child(7),
  #logo-content > :nth-child(8),
  #logo-content > :nth-child(9),
  #logo-content > :nth-child(10),
  #logo-content > :nth-child(2){

    /* Color for O T T T I and S */
    stroke: var(--color-3)
  }
  

@keyframes dash {
  0% {
    stroke-dasharray: 0 0 0 360 0 360;
  }

  50% {
    stroke-dasharray: 0 0 360 0 360 0;
  }

  100% {
    stroke-dasharray: 0 360 0 360 0 360;
    }
}

 @keyframes spin {
    0% {
      stroke-dashoffset: 0;
    }
  
    50% {
      stroke-dashoffset: 360
    }
    100% {
      stroke-dashoffset: 0
    }
} 
  
@media  screen and (max-width:1300px) {
  #logo{
    height:10rem;
    transform: scale(3);
    /* width: 60rem; */
    
  }
}
@media  screen and (max-width:843px) {
  #logo{
    height:5rem;
    transform: scale(3);
    /* width: 60rem; */
    
  }
}
@media  screen and (max-width:430px) {
  #logo{
    height:4rem;
    transform: scale(3);
    /* width: 60rem; */
    
  }
}



  