@import url('./Utils/Color.css');
.card{
    display:flex;
    flex-direction:column;
    padding:.5rem;
    margin:.5rem;
    border-radius:5px;
}
.card-row-top{
    display:flex;
    flex-direction:row;
    justify-content: left;
}
.card-row-bottom{
    display:flex;
   flex-direction:row;
}
.card-column-left{
    flex:1;
}
.card-column-right{
    flex:3
}



.card-title{
    margin-bottom:0;
    font-size:18px;
    margin-top:.3rem;
    
}
.card-description{
    color:white;
    font-size: 16px;
    line-height: 1.6;
    font-family: 'Roboto', sans-serif;
    margin-top:.5rem;

}
.duration{
    position:relative;
    margin:0;
    top:.7rem;
    white-space: pre-wrap;
    font-size:.8rem;
    font-weight: 400;
    color:var(--color-6);
    
}
.card-skills, .duration{
    margin-right:.3rem;
    margin-top:1rem;
} 

.card:hover{
    box-shadow: -1px 3px 3px rgba(0, 0, 0, 0.2); 
    background: var(--color-1a)
}



/* Work card only */
.card-company{
    margin-top:.2rem;
    margin-bottom:0;
    font-size: .9rem;
    font-weight: 600;
    /* font-style: oblique; */
    color:var(--color-4)
}

@media  screen and (max-width:1199px) {
    .card-row-bottom{
       flex-direction:column-reverse;
    }
    .card-row-top{
        flex-direction:column-reverse;
        
        /* align-items:end; */
     }
     
    .card-skills{
        margin-top:.5rem;
    } 
    .duration{
        margin-top:-.5rem;
        margin-bottom: .3rem;
    }

    
    
}