@import url('./Utils/Color.css');
.project-card{
    display:flex;
    padding:.5rem;
    margin:.5rem;
    border-radius:5px;
    flex-direction:row;
    align-items:top;
}
.project-card-row-top{
    display:flex;
    flex-direction:row;
    justify-content: left;
}
.project-card-row-bottom{
    display:flex;
   flex-direction:row;
}
.project-card-column-left{
    flex:1;
}
.project-card-column-right{
    flex:3
}



.project-card-title{
    margin-bottom:0;
    font-size:18px;
    margin-top:.3rem;
    
}
.project-card-description{
    color:white;
    font-size: 16px;
    line-height: 1.6;
    font-family: 'Roboto', sans-serif;
    margin-top:.5rem;

}



.project-card:hover{
    box-shadow: -1px 3px 3px rgba(0, 0, 0, 0.2); 
    background: var(--color-1a)
}

.project-card-image{
    margin-top:.67rem;
    margin-bottom:auto;
    object-fit: cover;
    width:160px;
    height:100px;
    border-radius:5px;
}
.project-card:hover .project-card-title{
    color:var(--color-3);
    text-decoration: underline;
}
.project-card:hover .project-card-image {
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.3)
  }
.project-card:hover{
    cursor:pointer;
}



@media  screen and (max-width:1199px) {
   .project-card{
    flex-direction:column-reverse;
   }
    .card-image{
        margin-right:.5rem;
        width:200px;
        height:100px;
    }
    
    
}