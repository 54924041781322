@import url('./Utils/Color.css');

.about-paragraph {
    color:white;
    font-size: 16px;
    line-height: 1.6;
    font-family: 'Roboto', sans-serif;
    margin:1rem;
  }
  .about-paragraph a{
    color: var(--color-5)
  }
  .about-paragraph a:hover{
    text-decoration: underline;
    cursor:pointer;
  }
