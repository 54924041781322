@import url('./Utils/Color.css');
.section-header{
    margin-left:1rem;
    margin-bottom:0rem;
    color: var(--color-3);
}

.section-header::after {
    content: '';
    display: block;
    width: 80%;
    height: 1px;
    background-color: var(--color-3);
    margin-bottom: 10px;
  }