

.body-content{
    max-width:80rem;
    display: flex;
 
    padding:4px;
    margin-top:5rem;
    overflow-y: visible;
}


@media  screen and (max-width:1199px) {
    .body-content{
        flex-direction: column;
        margin-top:0;
    }
    
}
