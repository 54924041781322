@import url('./Utils/Color.css');


/* Non WebKit-based browsers */
.scroll-section{
  display: flex;
  flex-direction: column;
  gap:4rem;
  text-align:left;
  color:white;
  
  opacity:0;
}



