@import url('./Utils/Color.css');
.nav{
  margin-top:3rem;
  display: flex;
  flex-direction: column;
  margin-left: 1rem; 
}

.nav > li{
    display:inline;
    list-style:none ;
    text-align: left;
    color:white;
    font-size:1.3rem;
    margin-bottom:.5rem;
    font-weight: 600;
    cursor:pointer
}
  
.nav a {
  text-decoration: none;
  font-size: 18px;
  /* padding: 10px 20px; */
  transition: text-shadow 0.3s ease;
}

.nav a:not(.active):hover {
  color:var(--color-5);
  text-shadow: 0 0 5px rgba(15, 164, 175, 0.7), 0 0 10px rgba(15, 164, 175, 0.5);
}
.active{
  color:var(--color-3)
}
@media screen and (max-width: 1199px){
  .nav-container{
    display:flex;
   justify-content:end;
    height: 3.5rem;
    position:fixed;
    top:0;
    right:0;
    z-index: 1000;
    width:100vw;
    
    align-items: center;
    background: var(--color-2a);
    backdrop-filter: blur(30px);
    
  }
  .nav{
    margin:0;
    margin-top:4rem;
    /* align-items:center; */
    top:0;
    left:110%;
    height:100vh;
    width:100vw;
    position: absolute;
    background:var(--color-1);
    /* background: var(--color-2a);
    backdrop-filter: blur(30px); */
    transition:left .5s ease;
    /* right:-100%; */
  }
  .nav.active{
    left:0;
  }
  .nav li{
    margin-left:2rem;
    margin-right:2rem;
    padding:1rem;
    border-radius:2px;
    background: var(--color-2a);
    /* backdrop-filter: blur(30px); */
 }
  .nav li a{
     font-size:2rem;
  }


}
  