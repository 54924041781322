@import url('./Utils/Color.css');
.bio{
    position: relative;
    color:white;
    text-align:left;
    padding: 0 1rem;
    margin:1rem;
    /* border-left: solid 3px var(--color-3); */
    overflow-x: hidden;
    /* min-width:500px; */
    
}
.bio::before {
    content: '';
    position:absolute;
    left:0;
    top:0;
    width: 4px;
    height: 100%;
    background-color:var(--color-3);
    border-radius: 30px;
}
.name {
    font-size: 4.5rem;
    line-height: 1;
    margin-top:0;
    margin-bottom:.4rem;
}
.title{
    margin:0;
    line-height: 1;

}

.sub-desc{
    color:var(--color-3);
    font-weight: 500;
    width:50%;
    /* padding:4px; */
    
    /* margin-right: 3rem */
    

}
.sub-desc > p{
    margin-top:0;
    margin-bottom:0;
    font-size:.9rem;
}
.desc-container{
    /* padding: .5rem; */
    border-radius: 5px 5px 0 0;
    display:flex;
    flex-direction: row;
    justify-content: left;
}
.desc{
    color:rgb(255, 255, 255);
    text-align: left; 
    max-width:80%;
   
}
@media  screen and (max-width:1199px) {
    .name{
        font-size:3.8rem
    }
    .sub-desc{
        max-width:12rem;
        padding:4px
        /* margin-right: 3rem */
        
    
    }
    
}