@import url('./Utils/Color.css');
.loading-screen{
    position:fixed;
    margin:0;
    padding:0;
    /* top:0; */
    right:0;
    bottom:0;
    width:100vw;
    height:100vh;
    background:var(--color-4);
    display:flex;
    align-items:center;
    justify-content:center;
    z-index: 9999;
    padding-bottom: 30px ;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    overflow-y: hidden;
    overflow-x:hidden;
    
}
