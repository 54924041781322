.column{
    max-width:60rem;
    margin-right: 0;
}
.column-left{
    
   height:90vh;
   display:flex;
   align-items: center;
}






@media  screen and (min-width:1200px) {
    .column{
        max-width:54rem;
        /* background-color: rgba(139, 118, 255, 0.3); */
        overflow-x: hidden;
        margin-right: calc(50% - 39rem);
    
    }
    
    .column-left{
        max-width:30rem;
        display:block;
        margin-right:0;
        position: fixed;
        left:calc(50% - 36rem);
    
    }
}
