@import url('./Utils/Color.css');
.skill-bubble {
    display: inline-block; /* Makes it behave like an inline element */
    vertical-align: middle;
    padding: 5px 8px; /* Optional: Adjust padding for some spacing around the text */
    background-color:var(--color-3); /* Background color */
    color:var(--color-1);
    border-radius: 20px; /* Pill shape */    margin: 3px;
    font-size: 12px; /* Text size */
    font-weight: 500; /* Optional: makes text bold */
    text-align: center; /* Center the text horizontally */
    white-space: nowrap; /* Prevents text wrapping */
  }
  


  /* @media  screen and (max-width:1199px) {
    .skill-bubble{
      
    }
    
} */
