@import url('./Utils/Color.css');

/* From Uiverse.io by vinodjangid07 */ 
#hamburger {
    display: none;
  }
  
  .toggle {
    position: relative;
    width: 30px;
    height: 35px;
    margin:1.2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
    transition-duration: .5s;
  }
  
  .bars {
    width: 100%;
    height: 4px;
    background-color: var(--color-2);
    border-radius: 4px;
  }
  
  #bar2 {
    transition-duration: .8s;
  }
  
  #bar1,#bar3 {
    width: 70%;
  }
  
  #hamburger:checked + .toggle .bars {
    position: absolute;
    transition-duration: .5s;
    background-color:var(--color-4);
  }
  
  #hamburger:checked + .toggle #bar2 {
    transform: scaleX(0);
    transition-duration: .5s;
  }
  
  #hamburger:checked + .toggle #bar1 {
    width: 100%;
    transform: rotate(45deg);
    transition-duration: .5s;
  }
  
  #hamburger:checked + .toggle #bar3 {
    width: 100%;
    transform: rotate(-45deg);
    transition-duration: .5s;
  }
  
  #hamburger:checked + .toggle {
    transition-duration: .5s;
    transform: rotate(180deg);
    margin-right:2rem;
  }
  @media screen and (min-width: 1200px){
    .toggle{
        display:none;
    }

  }